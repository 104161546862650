import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "submit" }
const _hoisted_2 = ["disabled"]

import { toRefs } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  title: {
    type: String,
    default: '',
  },
  isClickDisabled: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;
const { title, isClickDisabled } = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      disabled: _unref(isClickDisabled),
      class: _normalizeClass(['submit-btn', { activity: _unref(isClickDisabled) }])
    }, _toDisplayString(_unref(title)), 11, _hoisted_2)
  ]))
}
}

})